import { Injectable, inject } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, catchError, delay, switchMap, tap, throwError } from "rxjs";
import { ToastNotificationService } from "src/app/shared/services/toast-notification.service";
import { ToastNotificationType } from "src/app/shared/enums/toast-notification-type.enum";
import { NotificationType } from "src/app/shared/enums/notification-type.enum";
import { AuthService } from "src/app/features/auth/services/auth.service";

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private readonly _authService = inject(AuthService);
  private readonly _toastNotificationService = inject(ToastNotificationService);
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this._toastNotificationService.showToast(
            NotificationType.APP_USER_UNAUTHORIZED,
            {
              severity: ToastNotificationType.error,
              life: 5000,
            }
          );
          return this.handleUnauthorizedError();
        }
        return throwError(() => error); // Diğer hataları dışa ilet.
      })
    );
  }

  // Unauthorized olduğunda logout işlemi 5 saniye sonra yapılacak.
  private handleUnauthorizedError(): Observable<HttpEvent<unknown>> {
    return new Observable(observer => {
      observer.next(); 
      observer.complete();
    }).pipe(
      delay(5000), // 5 saniye bekleme
      switchMap(() => this._authService.logout())
    );
  }
}
