<div class="w-full h-screen overflow-hidden bg-secondary-200">
  <p-blockUI [blocked]="blockUI()" />
  <app-loader />
  <router-outlet />
  <app-notification-toast />

  @defer {
    <app-environment-badge />
  }
</div>
