import { Component, inject, ViewEncapsulation } from "@angular/core";
import { MessageService } from "primeng/api";
import { ToastModule, ToastPositionType } from "primeng/toast";
import { ToastNotificationService } from "../../services/toast-notification.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    selector: "app-notification-toast",
    imports: [ToastModule],
    templateUrl: "./notification-toast.component.html",
    styleUrls: ["./notification-toast.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [MessageService]
})
export class NotificationToastComponent {
  private readonly _messageService = inject(MessageService);
  private readonly _toastNotificationService = inject(ToastNotificationService);

  constructor() {
    this._toastNotificationService.notificationToastItem
      .pipe(takeUntilDestroyed())
      .subscribe(toastOptions => {
        if (toastOptions) {
          this._messageService.add({
            key: "toast-notification",
            severity: toastOptions.severity,
            summary: toastOptions.summary,
            detail: toastOptions.detail,
            sticky: toastOptions.sticky,
            life: toastOptions.life,
            closable: toastOptions.closable,
          });
        } else {
          this._messageService.clear("toast-notification");
        }
      });
  }
}
