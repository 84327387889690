import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Provider } from "@angular/core";
import { CapthcaInterceptorService } from "../core/interceptor/captcha.interceptor";
import { ErrorCatchingInterceptor } from "../core/interceptor/error.interceptor";
import { TokenInterceptorService } from "../core/interceptor/token.interceptor";
import { LoaderInterceptor } from "../core/interceptor/loader.interceptor";
import { UnauthorizedInterceptor } from "../core/interceptor/unauthorized.interceptor";
import { RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { environment } from "src/environments/environments";

export function provideCore(): Provider[] {
  return [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.captchaKey,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CapthcaInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ];
}
